import '../styles/global.css';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { appWithTranslation } from 'next-i18next';
import { QueryProvider } from '@dx-ui/framework-react-query';
import { getQueryProviderProps } from '@dx-ui/framework-react-query-env-vars';
import { useRef } from 'react';
import type { AppContext, AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import type { CorpUserInfo } from '../lib';
import {
  CorpUserInfoProvider,
  MetricsProvider,
  SessionProvider,
  SessionContextTypes,
} from '../lib';
import type { DehydratedState } from '@tanstack/react-query';
import { getAuthClient } from '../utils/auth';
import type { ServerResponse } from 'http';
import { LocationProvider } from '@dx-ui/framework-location-provider';
import { useManualPageLogger } from '@dx-ui/framework-logger';
import { isBrowser } from '@dx-ui/utilities-is-browser';

const SessionModal = dynamic(() => import('../components/SessionModal/SessionModal'));

const APP_NAME = 'dx-hiltonlink-ui';

type ExtendedServerResponse = ServerResponse & {
  locals?: {
    auth: {
      UserClaims: {
        firstName?: string;
        lastName?: string;
        emailAddress?: string;
        phoneNumber?: string;
      };
    };
  };
};

type IAppWrapperProps = {
  pageProps: {
    dehydratedQueryState: DehydratedState;
  };
  corpUserInfo: CorpUserInfo;
} & AppProps;

function useCreateOnce<T>(fn: () => T) {
  const ref = useRef<T | null>(null);
  if (!ref.current) {
    ref.current = fn();
  }
  return ref.current;
}

const queryProps = getQueryProviderProps();

function App({ Component, pageProps, router, corpUserInfo }: IAppWrapperProps) {
  const corpUserContactInfo = useCreateOnce(() => corpUserInfo);
  useManualPageLogger(router.pathname, APP_NAME);

  return (
    <QueryProvider
      {...queryProps}
      dehydratedQueryState={pageProps.dehydratedQueryState}
      appName="dx-hiltonlink-ui"
      routerLocale={router.locale || 'en'}
      oneLinkConfig={null}
      authenticatedOperationNames={[
        'guest',
        'brand_eventPageByRatePlan_hotel',
        'brand_eventRatePlan_hotel',
        'createEventPage',
        'updateEventPage',
        'publishEventPage',
      ]}
      handleAuthenticatedOperationError={async () => {
        await router.push(`/${router.locale}/my-event/sign-in/`);
        return null;
      }}
    >
      <SessionProvider contextType={SessionContextTypes.None}>
        <MetricsProvider>
          <LocationProvider>
            <CorpUserInfoProvider corpUserInfo={corpUserContactInfo}>
              <SessionModal />
              <Component {...pageProps} router={router} />
            </CorpUserInfoProvider>
          </LocationProvider>
        </MetricsProvider>
      </SessionProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryProvider>
  );
}

App.getInitialProps = async (appContext: AppContext) => {
  const { Component, ctx, router } = appContext;
  const { req } = ctx;
  const res = ctx.res as ExtendedServerResponse;
  const authClient = getAuthClient(req);
  // Wait for user to resolve
  await authClient.getGuestInfo();
  const isCorpUser = authClient.isCorpUser();
  const corpUserInfo = { firstName: '', lastName: '', emailAddress: '', phoneNumber: '' };
  const isAuthenticated = authClient.getIsLoggedIn();

  let pageProps: Partial<IAppWrapperProps> = { router };

  if (!isBrowser) {
    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx);
    }

    if (isAuthenticated && isCorpUser && res && res.locals?.auth) {
      corpUserInfo.firstName = res.locals.auth.UserClaims?.firstName || '';
      corpUserInfo.lastName = res.locals.auth.UserClaims?.lastName || '';
      corpUserInfo.emailAddress = res.locals.auth.UserClaims?.emailAddress || '';
      corpUserInfo.phoneNumber = res.locals.auth.UserClaims?.phoneNumber || '';
    }
  }

  return {
    pageProps,
    corpUserInfo,
  };
};

App.displayName = 'HiltonLinkAppWrapper';

export default appWithTranslation<IAppWrapperProps>(App);
